<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="content">
            <div class="card-list">
              <template v-if="!$parent.checkoutSuccess">
              <div class="card-list__item order-details">
                <div class="title small">{{ $t(`Order details`) }}</div>
                <div class="desc">{{ $t(`Choose payment method:`) }}</div>
                <div class="radio-list">
                  <div class="list">
                    <div class="item" v-if="!$parent.fiatToCrypto || ($parent.fiatToCrypto && paymentMethod == 'card')">
                      <label>
                        <input v-if="$parent.fiatToCrypto" type="radio" v-model="paymentMethod" name="paymentMethod" value="card"/>
                        <input v-if="!$parent.fiatToCrypto" type="radio" v-model="paymentMethod" name="paymentMethod" value="crypto"/>
                        <div class="text">
                          <svg v-if="$parent.fiatToCrypto" class="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.667 17.841">
                            <g id="Layer_1" transform="translate(-2.13 -5.896)">
                              <path id="Контур_12558" data-name="Контур 12558" d="M6.467,23.737h16A4.338,4.338,0,0,0,26.8,19.4V10.229A4.338,4.338,0,0,0,22.464,5.9h-16a4.338,4.338,0,0,0-4.333,4.333v1.544c0,.008,0,.014,0,.021s0,.014,0,.021V19.4a4.338,4.338,0,0,0,4.333,4.333Zm16-1.766h-16A2.57,2.57,0,0,1,3.9,19.4V12.678H25.031V19.4A2.57,2.57,0,0,1,22.464,21.972Zm-16-14.31h16a2.57,2.57,0,0,1,2.567,2.567v.683H3.9v-.683A2.57,2.57,0,0,1,6.467,7.662Z" transform="translate(0 0)" fill="#7f8798"/>
                              <path id="Контур_12559" data-name="Контур 12559" d="M10.865,20.469H6.737a.883.883,0,1,0,0,1.766h4.128a.883.883,0,0,0,0-1.766Z" transform="translate(-0.436 -1.707)" fill="#7f8798"/>
                            </g>
                          </svg>
                          <div class="desc">{{ $parent.fiatToCrypto ? 'Visa' : 'Crypto' }} </div>
                        </div>
                      </label>
                    </div>
                    <div v-if="$parent.fiatToCrypto && paymentMethod == 'eps'" class="item">
                      <label>
                        <input type="radio" v-model="paymentMethod" name="paymentMethod" value="eps"/>
                        <div class="text">
                          <img src="@/images/methods/eps.svg" class="img"/>
                          <div class="desc">{{ $t(`Eps`) }}</div>
                        </div>
                      </label>
                    </div>
                    <div class="item" v-if="$parent.fiatToCrypto && paymentMethod == 'trustly'">
                      <label>
                        <input type="radio" v-model="paymentMethod" name="paymentMethod" value="trustly"/>
                        <div class="text">

                          <img src="@/images/methods/trustly.svg" class="img"/>
                          <div class="desc">{{ $t(`Trustly`) }}</div>
                        </div>
                      </label>
                    </div>
                    <div class="item" v-if="$parent.fiatToCrypto && paymentMethod == 'giropay'">
                      <label>
                        <input type="radio" v-model="paymentMethod" name="paymentMethod" value="giropay"/>
                        <div class="text">
                          <img src="@/images/methods/giropay.svg" class="img"/>
                          <div class="desc">{{ $t(`Giropay`) }}</div>
                        </div>
                      </label>
                    </div>
                    <div class="item" v-if="$parent.fiatToCrypto && paymentMethod == 'blik'">
                      <label>
                        <input type="radio" v-model="paymentMethod" name="paymentMethod" value="blik"/>
                        <div class="text">
                          <img src="@/images/methods/blik.svg" class="img"/>
                          <div class="desc">{{ $t(`Blik`) }}</div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="input-container">
                  <div class="desc">{{ $t(`Wallet:`) }}</div>
                  <input type="text" v-model="wallet"/>
                  <div class="clue">{{ $t(`Please provide your wallet`) }}</div>
                </div>
                <div class="total">
                  <div class="desc">{{ $t(`Total amount to pay:`) }}</div>
                  <div class="total-wrapper">
                    <div class="item">
                      <div class="text">
                        <div class="desc">{{ $t(`Date of order`) }}</div>
                        <div class="desc">{{currentDate}}</div>
                      </div>
                      <div class="title" :class="{'crypto': !$parent.fiatToCrypto}">{{ leftAmount }}</div>
                    </div>
                    <div class="item">
                      <div class="text">
                        <div class="desc">{{ $t(`Exchange rate`) }}</div>
                        <div class="desc">{{$parent.takeCurrency.crypto_currency}} = {{$parent.takeCurrency.price}} {{$parent.giveCurrency.symbol}}</div>
                      </div>
                      <div class="title" :class="{'crypto': $parent.fiatToCrypto}">{{ rightAmount }}</div>
                    </div>
                  </div>
                </div>
                <div class="total-bottom">
                  <router-link to="buy-crypto" class="button gray">{{ $t(`Cancel order`) }}</router-link>
                  <button class="button" @click="submit">{{ $t(`Continue`) }}</button>
                </div>
              </div>
              <div class="card-list__item-container">
                <div class="card-list__item your-card">
                  <div class="title small">{{ $t(`Your Card`) }}</div>
                  <div class="row">
                    <div class="title">{{ leftAmount }}</div>
                    <router-link to="buy-crypto" class="button gray">{{ $t(`Change amount`) }}</router-link>
                  </div>
                  <div class="desc">{{ rightAmount }}</div>
                  <div class="table">
                    <div class="row">
                      <div class="desc">{{ $t(`Exchange rate`) }}</div>
                      <div class="desc">1 {{$parent.takeCurrency.crypto_currency}} = {{$parent.takeCurrency.price}} {{$parent.giveCurrency.symbol}}</div>
                    </div>
                    <div class="row">
                      <div class="desc">{{ $parent.fiatToCrypto ? 'Coins' : 'Money' }} to receive</div>
                      <div class="desc">{{ rightAmount }}</div>
                    </div>
                  </div>
                </div>
                <div class="timer">
                  <div class="title">{{ hours }}:{{ minutes }}:{{ seconds }}</div>
                  <div class="desc">{{ $t(`The price is fixes for 1 hour`) }}</div>
                </div>
              </div>
              </template>
              <template v-else>
                <div class="card-list__item order-success">
                  <div class="order-submit-success">
                    <div class="order-submit-success__title">{{ $t(`Order successfully submitted`) }}</div>
                    <div class="order-submit-success__message">
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import fiatSection from "@/components/FiatSection.vue";

export default {
  name: 'OrderDetails',
  props: [],
  components: {
  },
  data: function() {
    return {
      paymentMethod: 'card',
      wallet: '',
      hours: 1,
      minutes: 0,
      seconds: 0,
      timer: null,
      currentDate: '',
      submitSuccess: false
    }
  },
  mounted: function() {
    if (this.$parent.paymentMethod) {
      this.paymentMethod = this.$parent.paymentMethod
    }
    
    this.$parent.changeCheckoutSuccess(false);
    this.startTimer();
    this.getCurrentDate();
  },
  methods: {
    getCurrentDate() {
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0];

      this.currentDate = formattedDate;
    },
   startTimer() {
      this.timer = setInterval(() => {
        if (this.hours === 0 && this.minutes === 0 && this.seconds === 0) {
          clearInterval(this.timer);
        } else {
          if (this.seconds > 0) {
            this.seconds--;
          } else {
            if (this.minutes > 0) {
              this.minutes--;
              this.seconds = 59;
            } else {
              this.hours--;
              this.minutes = 59;
              this.seconds = 59;
            }
          }
        }
      }, 1000);
    },
    submit() {
      this.$parent.openPersonalInfoModal();
      this.$emit('setWallet', this.wallet);
      this.$emit('setPaymentMethod', this.paymentMethod);
    }
  },
  computed: {
    fiatSection() {
      return fiatSection
    },
    leftAmount()  {
      const parent = this.$parent;
      return parent.fiatToCrypto ? `${parent.giveCurrency.symbol} ${parseFloat(parent.giveAmount ).toFixed(2)}` : `${parent.takeAmount} ${parent.takeCurrency.crypto_currency}`;
    },
    rightAmount() {
      const parent = this.$parent;
      return parent.fiatToCrypto ? `${parent.takeAmount} ${parent.takeCurrency.crypto_currency}` : `${parent.giveCurrency.symbol} ${parseFloat(parent.giveAmount ).toFixed(2)}`;
    }
  }
}
</script>