<template>
  <main class="main profile-page page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title">{{ $t(`Profile`) }}</div>
          <div v-if="status !== 'approved'" id="sumsub-websdk-container"></div>
          <div class="status title">Status: {{ status }}</div>
          <div class="form">
            <div class="row">
              <div class="title small">{{ $t(`Name`) }}</div>
              <label>
                <input type="text" :placeholder="$t(`Name`)" v-model="name" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.name">{{ errors['name'][0] }}</div>
                </transition>
              </label>
              <label>
                <input type="text" :placeholder="$t(`Last name`)" v-model="surname" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.surname">{{ errors['surname'][0] }}</div>
                </transition>
              </label>
            </div>
            <div class="row">
              <div class="title small">{{ $t(`Email`) }}</div>
              <label>
                <input type="text" :placeholder="$t(`Email`)" v-model="email" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.email">{{ errors['email'][0] }}</div>
                </transition>
              </label>
              <button class="button" @click="updateProfileData">
                <span>{{ $t(`Update`) }}</span>
              </button>
              <!-- <transition name="fade">
                <div class="desc green" v-if="profileUpdateSuccessMessaage">{{ profileUpdateSuccessMessaage }}</div>
              </transition> -->
            </div>
            <div class="row pass-row">
              <div class="title small">{{ $t(`Additional Data`) }}</div>
              <label>
                <div class="desc">{{ $t(`Address`) }}</div>
                <input type="text" :placeholder="$t(`Address`)" v-model="address" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.address">{{ errors['address'][0] }}</div>
                </transition>
              </label>
              <label>
                <div class="desc">{{ $t(`Postal Code`) }}</div>
                <input type="text" :placeholder="$t(`Postal Code`)" v-model="postCode" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.post_code">{{ errors['post_code'][0] }}</div>
                </transition>
              </label>

              <label>
                <div class="desc">{{ $t(`City`) }}</div>
                <input type="text" :placeholder="$t(`City`)" v-model="city" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.city">{{ errors['city'][0] }}</div>
                </transition>
              </label>

              <label v-if="countryOptions.length > 1">
                <div class="desc">{{ $t(`Country`) }}</div>
                <Dropdown :options="countryOptions" value-key="id" label-key="title" placeholder="Country"
                  :default-value="defaultCountry" container-class="country-dropdown"
                  :container-style="{ width: '100%' }" :clearable="true" @select="onCountrySelect"
                  :not-selected-option-style="{ color: '#a9a9a9' }" @clear="onCountryClear" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.country_id">{{ errors['country_id'][0] }}</div>
                </transition>
              </label>

              <label>
                <div class="desc">{{ $t(`Phone`) }}</div>
                <VuePhoneNumberInput v-model="phone" :only-countries="countryOptionsIso"
                  :default-country-code="defaultCountryIso" @update="phoneDataUpdate($event)" />
                <transition name="fade">
                  <div class="desc red error-message" v-if="errors.phone">{{ errors['phone'][0] }}</div>
                </transition>
              </label>

              <button class="button" @click="updateProfileData">
                <span>{{ $t(`Update`) }}</span>
              </button>
            </div>
            <div class="row pass-row">
              <div class="title small">{{ $t(`Change Password`) }}</div>
              <label>
                <div class="desc">{{ $t(`Enter current password:`) }}</div>
                <input type="text" :placeholder="$t(`Current password`)" v-model="curPass" />
              </label>
              <label>
                <div class="desc">{{ $t(`New Password:`) }}</div>
                <input type="text" :placeholder="$t(`New password`)" v-model="newPass" />
              </label>
              <label>
                <div class="desc">{{ $t(`Re-type New Password:`) }}</div>
                <input type="text" :placeholder="$t(`Confirm password`)" v-model="repeatPass" />
              </label>
              <button class="button" @click="updatePassword">
                <span>{{ $t(`Update`) }}</span>
              </button>
              <transition name="fade">
                <div class="desc red error-message" v-if="passUpdateErrorMessaage">{{ passUpdateErrorMessaage }}</div>
              </transition>
              <transition name="fade">
                <div class="desc green" v-if="passUpdateSuccessMessaage">{{ passUpdateSuccessMessaage }}</div>
              </transition>
            </div>
            <div class="row logout-row">
              <div class="title small">{{ $t(`Log Out`) }}</div>
              <button class="button" @click="$parent.logout">
                <span>{{ $t(`Log Out`) }}</span>
              </button>
            </div>
            <div class="row">
              <div class="title small">{{ $t(`Account Activity`) }}</div>
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <td class="title">{{ $t(`ID`) }}</td>
                      <td class="title">{{ $t(`Date`) }}</td>
                      <td class="title">{{ $t(`Status`) }}</td>
                      <td class="title">{{ $t(`Buy`) }}</td>
                      <td class="title">{{ $t(`Pay`) }}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in $parent.history" :key="i">
                      <td class="desc">{{ item.id }}</td>
                      <td class="desc">{{ $parent.formatDate(item.created_at) }}</td>
                      <td class="desc">{{ item.status }}</td>
                      <td class="desc">{{ item.buy }} {{ $parent.currency }}</td>
                      <td class="desc">{{ item.pay }} {{ $parent.currency }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import snsWebSdk from '@sumsub/websdk';

export default {
  name: 'Profile',
  props: [],
  components: {
  },
  data: function () {
    return {
      curPass: '',
      newPass: '',
      repeatPass: '',
      email: '',
      name: '',
      surname: '',
      phone: '',
      // profileUpdateSuccessMessaage: '',
      // profileUpdateErrorMessaage: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      token: '',
      status: '',

      errors: {},
      timeoutId: null,

      address: null,
      postCode: null,
      city: null,
      country: null,
      phoneCountryCode: '',
      countryOptions: [],
      countryOptionsIso: [],

      convertedShortName: '',
    }
  },
  computed: {
    defaultCountryIso() {
      return this.phoneCountryCode || this.countryOptionsIso[0];
    },
    defaultCountry() {
      if (this.country && this.countryOptions.length > 0) {
        return this.countryOptions.find(option => option.id === this.country?.id) || this.countryOptions[0];
      } else {
        return null;
      }
    },
  },
  watch: {
  },
  mounted() {
    this.getCountries();

    this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {

        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
        this.status = res.data.status;

        this.address = res.data.address;
        this.postCode = res.data.post_code;
        this.city = res.data.city;
        this.country = res.data.country;
        this.phoneCountryCode = res.data.phone_country_code;

      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    this.$http.get(process.env.VUE_APP_API + 'sumsub-token')
      .then((res) => {


        this.token = res.data.token;
        this.launchWebSdk(this.token, this.email, this.phone)
      })
      .catch(() => {

      })


  },
  methods: {
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational || e.phoneNumber;
    },
    getCountries() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
        .then((res) => {
          this.countryOptions = res.data.payload
          this.countryOptionsIso = res.data.payload.map(obj => obj.iso)
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
    },
    onCountrySelect(country) {
      this.country = country;
    },
    onCountryClear() {
      this.country = null;
    },
    launchWebSdk(accessToken, applicantEmail, applicantPhone) {
      let snsWebSdkInstance = snsWebSdk.init(
        accessToken,
        // token update callback, must return Promise
        // Access token expired
        // get a new one and pass it to the callback to re-initiate the WebSDK
        () => this.getNewAccessToken()
      )
        .withConf({
          levelName: 'Without KYC',
          lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
          email: applicantEmail,
          phone: applicantPhone,
          i18n: { "document": { "subTitles": { "IDENTITY": "Upload a document that proves your identity" } } },
          uiConf: {
            customCssStr: ":root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}"
          }
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        // see below what kind of messages WebSDK generates
        .on('idCheck.stepCompleted', (payload) => {
          console.log('stepCompleted', payload)
        })
        .on('idCheck.onError', (error) => {
          console.log('onError', error)
        })
        .build();

      // you are ready to go:
      // just launch the WebSDK by providing the container element for it
      snsWebSdkInstance.launch('#sumsub-websdk-container')
    },
    getNewAccessToken() {
      return Promise.resolve(this.token)// get a new token from your backend
    },
    updateProfileData() {
      this.errors = {}
      let profileData = {
        "email": this.email,
        "name": this.name,
        "surname": this.surname,
        "address": this.address,
        'city': this.city,
        'post_code': this.postCode,
        "country_id": this.country ? this.country?.id : null,
        "phone": this.phoneFull,
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
        .then((res) => {
          if (res.data.status == "OK") {
            this.errors = {}
            this.$toast.success('Profile successfully updated!');

            // this.profileUpdateSuccessMessaage = 'Success';
            // setTimeout(() => {
            //   this.profileUpdateSuccessMessaage = '';
            // }, 3000);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
          this.errors = res.response.data.errors;

          clearTimeout(this.timeoutId); // cancel previous timeout
          this.timeoutId = setTimeout(() => {
            // this.profileUpdateErrorMessaage = '';
            this.errors = {};
          }, 3000);

          // this.profileUpdateErrorMessaage = res.response.data.message;
          // setTimeout(() => {
          //   this.profileUpdateErrorMessaage = '';
          // }, 3000);
        })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
        .then((res) => {
          if (res.data.status == "OK") {
            this.passUpdateSuccessMessaage = 'Success';
            this.curPass = '',
              this.newPass = '',
              this.repeatPass = '';
            setTimeout(() => {
              this.passUpdateSuccessMessaage = '';
            }, 3000);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
          this.passUpdateErrorMessaage = res.response.data.message;
          setTimeout(() => {
            this.passUpdateErrorMessaage = '';
          }, 3000);
        })
    },
  }
}
</script>