<template>
	<footer class="footer">
		<div class="wrapper">
			<ul class="nav">
				<template v-for="item in $parent.footerNav">
					<li class="nav__item" :key="item.title">
						<router-link class="desc" :to="item.path">
							{{ item.title }}
						</router-link>
					</li>
				</template>
				<template v-for="item in $parent.textPageList">
					<li class="nav__item" :key="item.id">
						<a class="desc" @click="$parent.goToPage(item)">
							{{ item.title }}
						</a>
					</li>
				</template>
			</ul>
			<div v-if="hasCountryObject" class="nav__item allowed-countries">
				<a @click="$parent.goToAllowedCountries" class="desc">{{ $t(`Allowed countries`) }}</a>
			</div>
		</div>
		<div class="wrapper wrapper_social">
			<div class="social-links" v-if="$parent.socialLinks.length > 0">
				<div v-for="link in $parent.socialLinks" :key="link.img" class="social_links__link">
					<a :href="link.link">
						<img :src="link.img" :alt="link.link" />
					</a>
				</div>
			</div>
		</div>
		<div class="wrapper">
			<div v-if="$parent.legalInfo" class="desc">{{ $parent.legalInfo }}</div>
			<div class="list">
				<!-- <div class="item" v-if="$parent.phone">
					<div class="desc">{{ $t(`Phone:`) }}</div>
					<a :href="'tel:' + $parent.phone" class="desc">{{$parent.phone}}</a>
				</div>
				<div class="item" v-if="$parent.email">
					<div class="desc">{{ $t(`E-mail:`) }}</div>
					<a :href="'mailto:' + $parent.email" class="desc">{{$parent.email}}</a>
				</div> -->
			</div>
		</div>
		<div class="wrapper">
			<div class="desc">© {{ new Date().getFullYear() }} All Rights Reserved</div>

			<ul class="payment-methods-list" v-if="paymentMethodsList && paymentMethodsList.length">
				<li v-for="image in allImages" :key="image" class="image-wrapper">
					<img :src="image" class="support-icon img" />
				</li>
			</ul>
			<!-- <ul class="list">
				<li class="item">
				<img src="./../images/mc.svg" class="img"/>
				</li>
				<li class="item">
				<img src="./../images/visa.svg" class="img"/>
				</li>
				<li class="item">
				<img src="./../images/3ds.png" class="img"/>
				</li>
				<li class="item">
				<img src="./../images/methods/eps.svg" class="img"/>
				</li>
				<li class="item">
				<img src="./../images/methods/trustly.svg" class="img"/>
				</li>
				<li class="item">
				<img src="./../images/methods/giropay.svg" class="img"/>
				</li>
				<li class="item">
				<img src="./../images/methods/blik.svg" class="img"/>
				</li>
			</ul> -->
		</div>
	</footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function () {
		return {
			// legalInfo: '',
			// email: '',
			// phone: ''
		}
	},
	methods: {
		getImages(method) {
			const images = [];
			if (method.code === 'card') {
				if (method.title === 'Visa') {
					images.push(require('@/images/visa.svg'));
				} else if (method.title === 'Mastercard') {
					images.push(require('@/images/mc_symbol.svg'));
				} else if (method.title === 'Visa/Mastercard') {
					images.push(require('@/images/visa.svg'), require('@/images/mc_symbol.svg'));
				}
				images.push(require('@/images/3ds.png'));
			}
			else if (method.code === 'eps' || method.title === 'EPS') {
				images.push(require('@/images/eps.svg'));
			} else if (method.code === 'trustly' || method.title === 'Trustly') {
				images.push(require('@/images/trustly.svg'));
			} else if (method.code === 'giropay' || method.title === 'GiroPay') {
				images.push(require('@/images/giropay.svg'));
			} else if (method.code === 'blik' || method.title === 'Blik') {
				images.push(require('@/images/blik.svg'));
			} else if (method.code === 'paidby' || method.title === 'Paidby') {
				images.push(require('@/images/paidby.svg'));
			} else if (method.code === 'klarna' || method.title === 'Klarna') {
				images.push(require('@/images/klarna.svg'));
			} else if (method.code === 'sofortuberweisung' || method.title === 'Sofortuberweisung') {
				images.push(require('@/images/sofortuber.svg'));
			} else if (method.code === 'ideal' || method.title === 'iDEAL') {
				images.push(require('@/images/ideal.svg'));
			} else if (method.code === 'bancontact' || method.title === 'Bancontact') {
				images.push(require('@/images/bancontact.svg'));
			}
			return images;
		}
	},
	computed: {
		paymentMethodsList() {
			if (!this.$parent.paymentMethods) return [];

			const flatPaymentMethods = [].concat(...Object.values(this.$parent.paymentMethods));

			const uniquePaymentMethodsMap = new Map();
			flatPaymentMethods.forEach(pm => {
				const uniqueKey = `${pm.title}-${pm.code}`;
				if (!uniquePaymentMethodsMap.has(uniqueKey)) {
					uniquePaymentMethodsMap.set(uniqueKey, pm);
				}
			});

			const uniquePaymentMethods = Array.from(uniquePaymentMethodsMap.values());
			return uniquePaymentMethods;
		},
		allImages() {
			return this.paymentMethodsList.flatMap(this.getImages);
		},
		hasCountryObject() {
			return this.$parent.textPageList.some(obj => obj.hasOwnProperty("is_countries") && obj.is_countries === 1);
		},
	},
	mounted() {
		// this.$http.get(process.env.VUE_APP_API + 'settings')
		// .then((res) => {
		// 	this.legalInfo = res.data.payload.legal_info;
		// 	this.email = res.data.payload.support_email;
		// 	this.phone = res.data.payload.support_phone;
		// })
		// .catch(() => {
		//
		// })
	}
}
</script>

<style lang="scss" scoped>
.footer .payment-methods-list {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	.image-wrapper {
		/* background-color: white; */
		display: flex;
		align-items: center;
		justify-content: center;
		height: 32px;
		max-width: 70px;
	}

	.support-icon.img {
		max-width: 100%;
		max-height: 100%;
		height: 100%;
		object-fit: contain;
		display: block;
	}
}
</style>