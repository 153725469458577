<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title">{{ $t(`Buy Cryptocurrency`) }}</div>
          <div class="content">
            <div class="card-list">
              <div class="card-list__item give">
                <div class="item__header">
                  <div class="title small">{{ $t(`Give`) }}</div>
                  <div v-if="$parent.wallets && $parent.wallets.length" class="exchange">
                    <ExchangeButton @click="toggleFiatToCrypto" />
                  </div>
                </div>

                <FiatSection v-if="fiatToCrypto" v-bind:give-amount.sync="giveAmount" :change-take-amount="changeTakeAmount"
                             :default-take-currency="defaultTakeCurrency" v-bind:give-currency.sync="giveCurrency"
                             v-bind:payment-method.sync="paymentMethod" :currencies="filteredCurrencies"
                             :toggle-fiat-to-crypto="toggleFiatToCrypto" @updatePaymentMethod="updatePaymentMethod"
                             :payment-methods="paymentMethods"
                />
                <CryptoSection v-else v-bind:take-currency.sync="takeCurrency" :give-currency="giveCurrency" v-bind:take-amount.sync="takeAmount"
                               :rates="rates" :filtered-rates-eur="filteredRatesEur" :filtered-rates-usd="filteredRatesUsd"
                               :toggle-fiat-to-crypto="toggleFiatToCrypto" :change-give-amount="changeGiveAmount"
                               v-bind:payment-method.sync="paymentMethod"
                               :payment-methods="paymentMethods"
                />

<!--                <div class="input-container">-->
<!--                  <input type="number" v-model="giveAmount" @input="changeTakeAmount(giveAmount)"/>-->
<!--                  <div v-if="giveCurrency.title == 'Euro'" class="desc">EUR</div>-->
<!--                  <div v-else-if="giveCurrency.title == 'US dollar'" class="desc">USD</div>-->
<!--                  <div v-else class="desc">{{giveCurrency.title}}</div>-->
<!--                </div>-->
<!--                <div class="radio-buttons">-->
<!--                  <div class="desc">{{ $t(`Choose a currency:`) }}</div>-->
<!--                  <div class="list" v-if="$parent.currencies">-->
<!--                    <div class="item" v-for="item in $parent.currencies" :key="item.title">-->
<!--                      <label>-->
<!--                        <input type="radio" v-model="giveCurrency" :value="item" @input="defaultTakeCurrency"/>-->
<!--                        <div v-if="item.title == 'Euro'" class="desc">EUR</div>-->
<!--                        <div v-else-if="item.title == 'US dollar'" class="desc">USD</div>-->
<!--                        <div v-else class="desc">{{item.title}}</div>-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="radio-list">-->
<!--                  <div class="list">-->
<!--                    <div class="item">-->
<!--                      <label>-->
<!--                        <input type="radio" v-model="paymentMethod" name="paymentMethod" value="card"/>-->
<!--                        <div class="text">-->
<!--                          <div class="desc">{{ $t(`Visa/MasterCard`) }}</div>-->
<!--                        </div>-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
              <div class="card-list__item take">
                <div class="top">
                  <div class="title small">{{ $t(`Take`) }}</div>
                  <div class="desc">1 {{takeCurrency.crypto_currency}} = {{takeCurrency.price}} {{giveCurrency.title}} <ExchangeButton v-if="$parent.wallets && $parent.wallets.length" @click="toggleFiatToCrypto" /></div>
                </div>
                <CryptoSection v-if="fiatToCrypto"  v-bind:take-currency.sync="takeCurrency" :give-currency="giveCurrency" v-bind:take-amount.sync="takeAmount"
                               :rates="rates" :filtered-rates-eur="filteredRatesEur" :filtered-rates-usd="filteredRatesUsd"
                               :change-give-amount="changeGiveAmount" v-bind:payment-method.sync="paymentMethod"
                               :payment-methods="paymentMethods"
                />
                <FiatSection v-else v-bind:give-amount.sync="giveAmount" :change-take-amount="changeTakeAmount"
                             :default-take-currency="defaultTakeCurrency" v-bind:give-currency.sync="giveCurrency"
                             v-bind:payment-method.sync="paymentMethod" :currencies="filteredCurrencies"
                             :payment-methods="paymentMethods"
                />
<!--                <div class="input-container">-->
<!--                  <input type="number" v-model="takeAmount" @input="changeGiveAmount(takeAmount);"/>-->
<!--                  <div class="desc">{{takeCurrency.crypto_currency}}</div>-->
<!--                </div>-->
<!--                <div class="radio-list">-->
<!--                  <div class="list" v-if="$parent.rates && giveCurrency.title == 'Euro'">-->
<!--                    <div class="item" v-for="item in filteredRatesEur" :key="item.id">-->
<!--                      <label>-->
<!--                        <input type="radio" v-model="takeCurrency" :value="item" />-->
<!--                        <div class="text">-->
<!--                          <div class="desc">{{item.crypto_currency}}</div>-->
<!--                          <div class="desc">{{item.price}} : 1</div>-->
<!--                        </div>-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="list" v-if="$parent.rates && giveCurrency.title == 'US dollar'">-->
<!--                    <div class="item" v-for="item in filteredRatesUsd" :key="item.id">-->
<!--                      <label>-->
<!--                        <input type="radio" v-model="takeCurrency" :value="item" />-->
<!--                        <div class="text">-->
<!--                          <div class="desc">{{item.crypto_currency}}</div>-->
<!--                          <div class="desc">{{item.price}} : 1</div>-->
<!--                        </div>-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
              <div class="card-list__item transactions">
                <div class="title small">{{ $t(`Transactions`) }}</div>
                <div class="desc" v-if="!$parent.orderHistory.length">{{ $t(`No orders yet`) }}</div>
                <div class="list" v-if="$parent.orderHistory.length">
                  <div class="item" v-for="(item, index) in $parent.orderHistory" :key="index">
                    <div class="desc align-left">#{{ item.id }}</div>
                    <div class="desc align-left"></div>
                    <div class="desc align-left">{{ item.created_at | moment('DD MMMM YYYY, HH:mm') }}</div>
                    <div class="desc align-left">{{ item.dest_amount }} {{ item.dest_asset.toUpperCase() }}</div>
                    <div class="desc align-left">{{ item.status }}</div>
                    <div class="desc align-left">- {{ item.source_asset }} {{ item.amount }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-list__item buy-item">
              <div class="desc">{{ $t(`Buy bitcoin with profitable exchange rate`) }}</div>
              <button :class="['button', {'disabled': !this.giveAmount || !this.takeAmount}]" v-if="$parent.isAuth" @click="submit">{{ $t(`Buy`) }}</button>
              <button class="button" v-if="!$parent.isAuth" @click="$parent.openSignInModal">{{ $t(`Buy`) }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import ExchangeButton from "@/components/ExchangeButton.vue";
import FiatSection from "@/components/FiatSection.vue";
import CryptoSection from "@/components/CryptoSection.vue";
import router from "@/router";

export default {
  name: 'BuyCrypto',
  props: ['currencies', 'crypto', 'rates'],
  components: {
    CryptoSection,
    FiatSection,
    ExchangeButton
  },
  data: function() {
    return {
      giveCurrency: {},
      takeCurrency: {},
      giveAmount: 100,
      takeAmount: 0,
      paymentMethod: 'card',
      fiatToCrypto: true
    }
  },
  watch: {
    currencies: {
      immediate: true,
      handler() {
        if(Object.values(this.filteredCurrencies)[0]) {
          this.giveCurrency = Object.values(this.filteredCurrencies)[0];
        }
      },
    },
    takeCurrency: {
      immediate: true,
      handler() {
        this.changeTakeAmount(this.giveAmount);
      },
    },
    rates: {
      immediate: true,
      handler(newValue) {
        if(newValue[0]) {
          this.takeCurrency = newValue[0];
        }
      },
    },
   
    
  },
  computed: {
    filteredRatesEur() {
      let rates = this.$parent.rates.filter(item => item.currency === 'EUR');

      if (!this.fiatToCrypto) {
        const parent = this.$parent;
        const wallets = parent.wallets && parent.wallets.map((currency) => currency.toUpperCase());
        rates = rates.filter(item => wallets && wallets.includes(item.crypto_currency.toUpperCase()))
      }

      return rates;
    },
    filteredRatesUsd() {
      let rates = this.$parent.rates.filter(item => item.currency === 'USD');

      if (!this.fiatToCrypto) {
        const parent = this.$parent;
        const wallets = parent.wallets && parent.wallets.map((currency) => currency.toUpperCase());
        rates = rates.filter(item => wallets && wallets.includes(item.crypto_currency.toUpperCase()))
      }

      return rates;
    },
    filteredCurrencies() {
      if (this.fiatToCrypto) {
        return Object.fromEntries(Object.entries(this.$parent.currencies).filter(([,data]) => data.sale));
      } else {
        return Object.fromEntries(Object.entries(this.$parent.currencies).filter(([,data]) => data.buy));
      }
    },
    paymentMethods() {
      return this.$parent.paymentMethods;
    }
  },
  methods: {
    updatePaymentMethod(method) {
      this.paymentMethod = method;
    },
    changeTakeAmount(newValue) {
      if (this.takeCurrency.price) {
        this.takeAmount = newValue / this.takeCurrency.price;
      } else {
        this.takeAmount = 0;
      }
    },
    changeGiveAmount(newValue) {
      if (this.takeCurrency.price) {
        this.giveAmount = this.takeCurrency.price * newValue;
      } else {
        this.giveAmount = 0;
      }
    },
    defaultTakeCurrency() {
      if (this.giveCurrency.title == 'Euro') {
        this.takeCurrency = this.filteredRatesEur[0];
      }
      if (this.giveCurrency.title == 'US dollar') {
        this.takeCurrency = this.filteredRatesUsd[0]
      }
    },
    submit() {
      const fiatAmount = this.giveAmount;

      if (!this.$parent.isEmailVerified()) {
        router.push('/email/verify/notice');
        return;
      }

      if (this.$parent.topOrderLimit && fiatAmount > this.$parent.topOrderLimit)  {
        this.$toast(`Max order price is ${this.$parent.topOrderLimit} EUR.`, {type: 'error'});
        return;
      }

      if (this.fiatToCrypto && this.$parent.minOrderPriceFiatToCrypto && fiatAmount < this.$parent.minOrderPriceFiatToCrypto) {
        this.$toast(`Min order price is ${this.$parent.minOrderPriceFiatToCrypto} EUR.`, {type: 'error'});
        return;
      } else if(!this.fiatToCrypto && this.$parent.minOrderPriceCryptoToFiat && fiatAmount < this.$parent.minOrderPriceCryptoToFiat) {
        this.$toast(`Min order price is ${this.$parent.minOrderPriceCryptoToFiat} EUR.`, {type: 'error'});
        return;
      }

      this.$emit('setCheckoutData', this.giveAmount, this.takeAmount, this.takeCurrency, this.giveCurrency, this.paymentMethod, this.fiatToCrypto)
      this.$router.push({path: '/order-details'});
    },
    toggleFiatToCrypto() {
      this.fiatToCrypto = !this.fiatToCrypto;
      this.paymentMethod = this.fiatToCrypto ? 'card' : 'crypto';
      let currencies = Object.entries(this.filteredCurrencies);

      if (!currencies.some(([,data]) => data.title === this.giveCurrency.title)) {
        this.giveCurrency = currencies[0][1];
        this.defaultTakeCurrency();
      }

      if (!this.fiatToCrypto) {
        if (this.giveCurrency.title === 'Euro') {
          if (this.filteredRatesEur && this.filteredRatesEur.length) {
            this.takeCurrency = this.filteredRatesEur[0];
          }
        } else {
          if (this.filteredRatesUsd && this.filteredRatesUsd.length) {
            this.takeCurrency = this.filteredRatesUsd[0];
          }
        }
      }
    }
  },
  mounted: function() {
    if (this.$parent.giveAmount) {
      this.giveAmount = this.$parent.giveAmount;
      this.takeAmount = this.$parent.takeAmount;
      this.takeCurrency = this.$parent.takeCurrency;
      this.giveCurrency = Object.values(this.filteredCurrencies)[0];
    }
  }
  
}
</script>